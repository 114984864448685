import React, { useState, useRef, useEffect, Fragment } from "react"
import Box from '@material-ui/core/Box';
import { IoMdMenu } from 'react-icons/io';
import { Link } from "react-scroll";
import ContactUs from './contactus'
import Particles from 'react-particles-js';
import { CSSTransition } from 'react-transition-group';
import logo from '../img/logo.svg'
import Banner from '../img/home-banner.png'
import TitleOne from '../img/title-1.svg'
import TitleTwo from '../img/title-2.svg'
import TitleThree from '../img/title-3.svg'
import TitleFour from '../img/title-4.svg'
import TitleFive from '../img/title-5.svg'
import ImageOne from '../img/home-1.png'
import ImageTwo from '../img/home-2.png'
import ImageThree from '../img/home-3.png'
import ImageFour from '../img/home-4.png'
import ImageFive from '../img/home-5.png'
import StepOne from '../img/step-1.svg'
import StepTwo from '../img/step-2.svg'
import StepThree from '../img/step-3.svg'
import StepFour from '../img/step-4.svg'
import StepFive from '../img/step-5.svg'
import StepSix from '../img/step-6.svg'
import "./index.scss";

const params = {
    "particles":
    {
        "number": { "value": 25, "density": { "enable": true, "value_area": 800 } }, "color": { "value": "#7dc54a" }, "shape": { "type": "circle", "stroke": { "width": 0, "color": "#000000" }, "polygon": { "nb_sides": 5 }, "image": { "src": "img/github.svg", "width": 100, "height": 100 } }, "opacity": { "value": 1, "random": true, "anim": { "enable": true, "speed": 1, "opacity_min": 0, "sync": false } }, "size": { "value": 6, "random": true, "anim": { "enable": false, "speed": 4, "size_min": 0.3, "sync": false } }, "line_linked": { "enable": false, "distance": 150, "color": "#ffffff", "opacity": 0.4, "width": 1 }, "move": { "enable": true, "speed": 1, "direction": "none", "random": true, "straight": false, "out_mode": "out", "bounce": false, "attract": { "enable": false, "rotateX": 600, "rotateY": 600 } }
    }, "interactivity": { "detect_on": "canvas", "events": { "onhover": { "enable": true, "mode": "bubble" }, "onclick": { "enable": true, "mode": "repulse" }, "resize": true }, "modes": { "grab": { "distance": 400, "line_linked": { "opacity": 1 } }, "bubble": { "distance": 250, "size": 0, "duration": 2, "opacity": 0, "speed": 3 }, "repulse": { "distance": 400, "duration": 0.4 }, "push": { "particles_nb": 4 }, "remove": { "particles_nb": 2 } } }, "retina_detect": true
}


const MenuLink = ({ link, title }) => {
    return (
        <Fragment>
            <Link
                className='link'
                activeClass="link"
                to={link}
                spy={true}
                smooth={true}
                offset={-75}
                duration={700}
            >{title}
            </Link>
        </Fragment>

    )
}

const MobileMenuLink = ({ bg, link, title, setMenuShow }) => {
    return (
        <Fragment>
            <Link
                onClick={() => {
                    setMenuShow(false)
                }}
                className={'menu-item ' + bg}
                activeClass={'menu-item ' + bg}
                to={link}
                spy={true}
                smooth={true}
                offset={window.innerWidth >= 1200 ? -100 : -80}
                duration={700}
            >{title}
            </Link>
        </Fragment>

    )
}

const Index = () => {
    const [menuShow, setMenuShow] = useState(false);
    const [flyInOne, setFlyInOne] = useState(false);
    const [flyInTwo, setFlyInTwo] = useState(false);
    const [flyInThree, setFlyInThree] = useState(false);
    const [flyInFour, setFlyInFour] = useState(false);
    const menuListRef = useRef(null)
    const menuBtnRef = useRef(null)
    const stepsRef = useRef(null)

    function handleClickOutside(event) {
        if (menuListRef.current && !menuListRef.current.contains(event.target) && menuBtnRef.current && !menuBtnRef.current.contains(event.target)) {
            setMenuShow(false)
        }
    }

    function handleFlyInAnimation(event) {
        if (window.scrollY > stepsRef.current.scrollHeight + stepsRef.current.clientHeight + window.innerHeight / 8) {
            setFlyInOne(true)
        }
    }


    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("scroll", handleFlyInAnimation);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.addEventListener("scroll", handleFlyInAnimation);
        };
    });


    return (
        <Box>
            <Particles
                style={{
                    width: '100%',
                    position: 'fixed',
                    zIndex: -1
                }}
                params={params} />

            {/* <div className='header-wrap-bg pc-show'>
                <div className='container'></div>
            </div> */}
            <Box className='header-wrap'>
                <Box className='header'>
                    <div className='logo'>
                        <img src={logo} alt="" />
                    </div>

                    <div className='header-right'>
                        {/* <div className='lang white'>
                            <div>EN </div>
                            <div className='lang-line'> | </div>
                            <div> CN</div>
                        </div> */}
                        <div className='links'>
                            <MenuLink link={'about'} title='About' />
                            <MenuLink link={'lactoferrin'} title='Lactoferrin' />
                            <MenuLink link={'forwho'} title='For Who' />
                            <MenuLink link={'safe-preparation'} title='Preparation' />
                            <MenuLink link={'contact'} title='Contact' />
                        </div>
                        <div ref={menuBtnRef}>
                            <IoMdMenu onClick={() => {
                                setMenuShow(!menuShow)
                            }} className='mobile-show menu-logo' color='#7DC54A' />
                        </div>

                    </div>
                </Box>
                <Box ref={menuListRef} display={menuShow ? 'block' : 'none'} className='menu-list mobile-show'>
                    <MobileMenuLink link={'about'} title='About' bg='menu-item-bg1' setMenuShow={setMenuShow} />
                    <MobileMenuLink link={'lactoferrin'} title='Lactoferrin' bg='menu-item-bg2' setMenuShow={setMenuShow} />
                    <MobileMenuLink link={'forwho'} title='For Who' bg='menu-item-bg3' setMenuShow={setMenuShow} />
                    <MobileMenuLink link={'safe-preparation'} title='Preparation' bg='menu-item-bg4' setMenuShow={setMenuShow} />
                    <MobileMenuLink link={'contact'} title='Contact' bg='menu-item-bg5' setMenuShow={setMenuShow} />
                </Box>

            </Box>

            <Box className='banner'>
                <div className='banner-img'>
                    <img src={Banner} alt="home-banner.png" />
                </div>
            </Box>

            <Box className='container about-us' name='about'>
                <div className='title'>
                    <img src={TitleOne} alt="" />
                </div>

                <div className='about-us-row'>
                    <div className='about-us-left'>
                        <div className='image'>
                            <img src={ImageOne} alt="" />
                        </div>
                    </div>
                    <div className='about-us-right'>
                        <div className='content1 green'>
                            <p> Nutrigreens Ferrimmune creates premium lactoferrin supplements, suitable for babies and adults. We formulate, test and pack our products in Sydney, Australia and source only the best ingredients from Australia*.</p> <p> Nutrigreens was established in 2017 and distributes it’s Ferrimmune product locally in Australia, Hong Kong and China. </p> <p>It all started with 2 passionate pharmacists, Andrew and John. Having experienced first-hand the extreme demand for Australian products in their retail stores, they set-out to create a product that could enhance the formulation of any baby formula, anywhere in the world. </p> <p>We’re proud to be Australian owned and made. Our Ferrimmune supplement includes all the best ingredients and we’ve left out all the colours, preservatives and artificial flavours.</p>
                        </div>
                        <div className='sub-content1'>*Nutrigreens Ferrimmune contains at least 80% Australian ingredients</div>
                    </div>
                </div>
            </Box>

            {/* <Box className='container microbiome' name='microbiome'>
                <div className='title title1 green'>Our Microbiome</div>
                <div className='row'>
                    <div className='col'>
                        <div>
                            <img src="/img/home-2.png" alt="" />
                        </div>
                        <div className='content1'>
                            Lorem ipsum dolor sit amet molestie consequat, vel illum dolore a facilisis at vero et con alia ton semat.
                        </div>

                    </div>

                    <div className='col'>
                        <div>
                            <img src="/img/home-3.png" alt="" />
                        </div>
                        <div className='content1'>
                            Lorem ipsum dolor sit amet molestie consequat, vel illum dolore a facilisis at vero et con alia ton semat.
                        </div>
                    </div>

                    <div className='col'>
                        <div>
                            <img src="/img/home-4.png" alt="" />
                        </div>
                        <div className='content1'>
                            Lorem ipsum dolor sit amet molestie consequat, vel illum dolore a facilisis at vero et con alia ton semat.
                        </div>
                    </div>

                </div>
            </Box> */}

            <Box className='lactoferrin container'>

                <div className='lactoferrin-bg'>
                    <div className='title' name='lactoferrin'>
                        <img src={TitleTwo} alt="" />
                    </div>
                    <div className='content content1 green'>
                        <p>Lactoferrin is a natural protein found in Human and Cow’s milk. It is found in very high levels in the baby’s first milk after birth, also known as Colostrum. Although still found in milk produced much later after birth, it’s levels are much lower.</p> <p> Many studies credit Lactoferrin as being the shield to bacterial and viral infections especially in newborn babies. It’s often used as an antioxidant to boost the immune system and to promote a healthy gut.</p>
                        <div className='lactoferrin-bg-1'></div>
                    </div>

                </div>
                <div className='lactoferrin-bg-2'></div>

                <div className='features' ref={stepsRef}>
                    <div className='image'>
                        <img src={ImageTwo} alt="" />
                    </div>
                    <div className='group-circles'>
                        <CSSTransition
                            in={flyInOne}
                            timeout={700}
                            classNames="circle-bg1"
                            unmountOnExit
                            onEntered={() => {
                                setFlyInTwo(true)
                            }}
                        >
                            <div className='circle-content '>
                                <div className='title2 white circle-title'>01</div>
                                <div className='title3 white feature'>
                                    <div>No preservatives </div>
                                    <div>No artificial flavours </div>
                                    <div>No colourings</div>
                                </div>
                            </div>
                        </CSSTransition>


                        <CSSTransition
                            in={flyInTwo}
                            timeout={700}
                            classNames="circle-bg2"
                            unmountOnExit
                            onEntered={() => {
                                setFlyInThree(true)
                            }}
                        >

                            <div className='circle-content circle-bg2'>
                                <div className='title2 white circle-title'>02</div>
                                <div className='title3 white feature'>
                                    <div>Small dosing size so it won’t affect your child’s feed</div>
                                </div>
                            </div>

                        </CSSTransition>

                        <CSSTransition
                            in={flyInThree}
                            timeout={700}
                            classNames="circle-bg3"
                            unmountOnExit
                            onEntered={() => {
                                setFlyInFour(true)
                            }}>
                            <div className='circle-content circle-bg3'>
                                <div className='title2 white circle-title'>03</div>
                                <div className='title3 white feature'>
                                    <div>Easily added to your child’s bottle or simply mixed into solids</div>
                                </div>
                            </div>

                        </CSSTransition>

                        <CSSTransition
                            in={flyInFour}
                            timeout={700}
                            classNames="circle-bg4"
                            unmountOnExit
                        >
                            <div className='circle-content circle-bg4'>
                                <div className='title2 white circle-title'>04</div>
                                <div className='title3 white feature'>
                                    <div>Australian made & owned</div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>



                </div>

            </Box>


            <Box className='for-who container' name='forwho'>
                <div className='title'>
                    <img src={TitleThree} alt="for who" />
                </div>
                <div className='subtitle'>*Suitable for the whole family.</div>
                <div className='content1 green'>Lactoferrin has immune benefits even for adults and can play an important role as a natural antibacterial, anti-fungal and antiviral.</div>
                <div className='for-who-img1'>
                    <div className='img-block'>
                        <div>
                            <img src={ImageFour} alt="" />
                        </div>
                    </div>

                </div>
                <div className='for-who-img2'>
                    <div>
                        <img src={ImageThree} alt="" />
                    </div>

                </div>

            </Box>

            <Box className='safe-preparation container' name='safe-preparation'>
                <div className='title'>
                    <img src={TitleFour} alt="for who" />
                </div>

                <div className='steps'>
                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepOne} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 1</div>
                            <div className='content1 green'>Always wash hands with soap and water and dry using a clean cloth. Clean the preparation surface thoroughly before preparing a feed.</div>
                        </div>
                    </div>

                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepTwo} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 2</div>
                            <div className='content1 green'>Clean all feeding equipment in hot soapy water and rinse thoroughly. Next, sterilise all feeding equipment by submerging bottles, teats and lids in a pan of water and boiling for 5 minutes, or using an approved steriliser.</div>
                        </div>
                    </div>

                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepThree} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 3</div>
                            <div className='content1 green'>Refer to the feeding guide below to check how much powder and water is needed. Pour the correct amount of previously boiled (now cooled) water into the sterilised bottle.</div>
                        </div>
                    </div>

                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepFour} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 4</div>
                            <div className='content1 green'>Use only the enclosed scoop, fill scoop and level off. Avoid compacting powder.</div>
                        </div>
                    </div>

                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepFive} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 5</div>
                            <div className='content1 green'>Add the exact amount of powder to the water in the bottle. Always add 1 level scoop of powder for each 50ml of water. Cap the bottle and mix thoroughly by shaking gently or swirling the bottle to dissolve the powder.</div>
                        </div>
                    </div>

                    <div className='step-block'>
                        <div className='image'>
                            <img src={StepSix} alt="step 1" />
                        </div>
                        <div className='content'>
                            <div className='step-title'>STEP 6</div>
                            <div className='content1 green'>Check the temperature of feed on the inside of your wrist before feeding. It should feel warm, but cool is better than too hot. Feed your baby immediately. Discard any feed that has not been consumed within 1 hour.</div>
                        </div>
                    </div>

                </div>

            </Box>

            <Box className='container contact-us' name='contact'>
                <div className='title'>
                    <img src={TitleFive} alt="contact" />
                </div>
                <div className={'form-block'}>
                    <div className={'left'}>
                        <div className='address green content1'>Suite 3005/100 Miller St, North Sydney NSW 2060.</div>
                        <div className='email green content1' style={{ visibility: 'hidden' }}>email: nutrigreen@info.au</div>
                        <ContactUs />
                    </div>
                    <div className={'right'}>
                        <div className='image'>
                            <img src={ImageFive} alt="home-5" />
                        </div>
                    </div>

                </div>

            </Box>

            <Box className='footer'>
                <div className='white content3'>Copyright Natrigreens 2019</div>
            </Box>
        </Box >
    )
}

export default Index;