import React, { useState } from "react"
import Box from '@material-ui/core/Box';
import "./index.scss";
import emailjs from 'emailjs-com';

const ContactUs = () => {


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [detail, setDetail] = useState('')

    const sentEmail = () => {

        const templateParams = {
            email: email,
            username: name,
            phone: phone,
            query_detail: detail
        };
        // emailjs.send('gmail', 'template_U7kmhQED', templateParams, 'user_EawcONlf9jKq6ddEStFxf')
        emailjs.send('sdfghjkl', 'template_lRwnHSJj', templateParams, 'user_ER3G7mBE1aMBXmdtATe1N')
            .then((response) => {
                alert('邮件已发送')
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
    }

    return (
        <Box className='form'>
            <form>
                <div>
                    <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div>
                    <input type="text" placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div>
                    <input type="text" placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div>
                    <textarea type="text" placeholder='Enquiry Details' value={detail} onChange={(e) => setDetail(e.target.value)} />
                </div>
                <div className='submit-block'>
                    <div className='submit' onClick={sentEmail}>Submit</div>
                </div>

            </form>

        </Box>

    )
}


export default ContactUs;