import React from 'react';
import Index from './pages/index'
import './App.css';

function App() {
  return (
    <Index />
  );
}

export default App;
